const covers = [
  "/images/covers/cover1.png",
  "/images/covers/cover2.png",
  "/images/covers/cover3.png",
  "/images/covers/cover4.png",
  "/images/covers/cover5.png",
  "/images/covers/cover6.png",
  "/images/covers/cover7.png",
  "/images/covers/cover8.png",
  "/images/covers/cover9.png",
];

const coversLength = covers.length;

const RandomCover = ({ id = 0 }) => {
  const index = id % coversLength;
  const coverUrl = covers[index];
  return <img src={coverUrl} alt={`Cover ${index}`} width={200} height={300} />;
};

export default RandomCover;
