"use client";

import Icon from "hardcover-ui/components/Icon";
import faBadgeCheck from "icons/solid/badge-check.svg";
import Container from "hardcover-ui/components/Container";
import Heading from "hardcover-ui/components/Heading";
import Text from "hardcover-ui/components/Text";

const url1Width = 482;
const url1Height = 482;
const url2Width = 668;
const url2Height = 661;

const basePath = `${process.env.NEXT_PUBLIC_RAILS_APP_URL}/signed/images/crop`;
const darkBook1x = `${basePath}?url=https://storage.googleapis.com/hardcover/images/landingPage/home-bookdrawer-dark.png&width=${url1Width}&height=${url1Height}&type=webp`;
const darkBook2x = `${basePath}?url=https://storage.googleapis.com/hardcover/images/landingPage/home-bookdrawer-dark.png&width=${url2Width}&height=${url2Height}&type=webp`;

const lightBook1x = `${basePath}?url=https://storage.googleapis.com/hardcover/images/landingPage/home-bookdrawer-white.png&width=${url1Width}&height=${url1Height}&type=webp`;
const lightBook2x = `${basePath}?url=https://storage.googleapis.com/hardcover/images/landingPage/home-bookdrawer-white.png&width=${url2Width}&height=${url2Height}&type=webp`;

const tracking = [
  "Your status for every book",
  "Progress on your current book(s)",
  "Your rating in half-star increments",
  "Your review",
  "Any number of lists for each book",
  "Genres, Moods, Tags and Content Warnings",
];

export default function TrackBooks() {
  return (
    <Container
      size="md"
      className="grid grid-cols-1 sm:grid-cols-2 gap-12 overflow-hidden"
    >
      <div className="order-last sm:order-first">
        <div className="hidden dark:block">
          <img
            src={darkBook1x}
            srcSet={`${darkBook1x} ${url1Width}w, ${darkBook2x} ${url2Width}w`}
            alt="track books"
            width={url1Width}
            height={url1Height}
            loading="lazy"
          />
        </div>
        <div className="block dark:hidden">
          <img
            src={lightBook1x}
            srcSet={`${lightBook1x} ${url1Width}w, ${lightBook2x} ${url2Width}w`}
            alt="track books"
            width={url1Width}
            height={url1Height}
            loading="lazy"
          />
        </div>
      </div>
      <div className=" flex flex-col gap-2">
        <Heading level={2} size="lg">
          Keep track of your books like never before.
        </Heading>
        <Text variant="secondary">
          Hardcover is built to handle all libraries, from the smallest to the
          most complex.
        </Text>
        <Text as="p" size="sm">
          You can track:
        </Text>

        <ul>
          {tracking.map((track) => (
            <li key={track} className="flex items-center space-x-2">
              <Icon icon={faBadgeCheck} className="text-indigo-500 mr-1" />
              <span>{track}</span>
            </li>
          ))}
        </ul>

        <p>...and do it publicly, privately or friends only for each book!</p>
      </div>
    </Container>
  );
}
