"use client";

import Sparkle from "react-sparkle";
import Text from "hardcover-ui/components/Text";
import Container from "hardcover-ui/components/Container";
import UserJoinButton from "components/join/UserJoinButton";
import Heading from "hardcover-ui/components/Heading";
import AppStoreBadges from "./AppStoreBadges";

const url1Width = 504;
const url1Height = 304;
const url2Width = 702;
const url2Height = 424;

const basePath = `${process.env.NEXT_PUBLIC_RAILS_APP_URL}/signed/images/crop`;
export const heroUrl1x = `${basePath}?url=https://storage.googleapis.com/hardcover/images/landingPage/heroImage.png&width=${url1Width}&height=${url1Height}&type=webp`;
export const heroUrl2x = `${basePath}?url=https://storage.googleapis.com/hardcover/images/landingPage/heroImage.png&width=${url2Width}&height=${url2Height}&type=webp`;

export default function Hero({ loggedIn }: { loggedIn: boolean }) {
  return (
    <div className="bg-gradient-to-b from-transparent dark:to-gray-900 to-gray-100 relative">
      <Container size="lg" className="z-10">
        <section className="grid sm:grid-cols-2 gap-4">
          <div className="flex justify-end items-end w-full h-full relative mt-2 sm:mt-0">
            <div className="absolute top-0 left-0 h-full w-full overflow-hidden z-0">
              <Sparkle
                color="#6366F1"
                count={30}
                minSize={5}
                maxSize={8}
                fadeOutSpeed={5}
                flickerSpeed="slowest"
                overflowPx={0}
              />
            </div>
            <img
              height={304}
              width={504}
              srcSet={`${heroUrl1x} ${url1Width}w, ${heroUrl2x} ${url2Width}w`}
              alt="Jules and two friends reading in front of a bookshelf"
              src={heroUrl1x}
              className="w-max -ml-1 sm:-ml-28 z-10"
            />
          </div>
          <div className="flex flex-col items-center sm:items-start gap-4 my-8 sm:my-16">
            <AppStoreBadges className="" />
            <Heading size="xxl" className="text-center sm:text-left">
              Book smart.
            </Heading>
            <Text size="xl" className="text-center sm:text-left">
              Track every book, share them with the world{" "}
              <Text variant="secondary">(or don&apos;t)</Text>
              <br className="hidden lg:block" />
              and find new life-changing reads.
            </Text>
            <div className="h-8">
              {loggedIn ? (
                false
              ) : (
                <UserJoinButton variant="primary" size="lg" tab="Join">
                  <>Join Hardcover</>
                </UserJoinButton>
              )}
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
}
